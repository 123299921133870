import React, { useEffect, useState } from "react";
import { isObjectEmpty, scrollBehaviorTo, windowIsExist } from "../../components/utils/index";
import { useI18next } from "gatsby-plugin-react-i18next";
import { ReactSVG } from "react-svg";
import { Layout } from "../../components/Layout/layout";
import Wrapper from "../../components/Layout/Wrapper/wrapper";
import { GetOrderHandler } from "../../services/api/GetOrder/GetOrderHandler";
import Loading from "../../components/atoms/Loading";
import { filterDataReview } from "../../services/componentSwitcher/filterDataReview";
import Button from "../../components/atoms/Button";
import Nav from "../../components/atoms/Nav";
import { GetApplicationNameHandler } from "../../services/api/GetApplicationName/GetApplicationNameHandler";
import { GetFormDetailsHandler } from "../../services/api/GetFormDetails/GetFormDetailsHandler";
import { buildAddons } from "../../components/utils/addons";
import OrderSummary from "../../components/organisms/OrderSummary";
import { ContactSummary } from "../../components/organisms/ContactSummary";
import { getUrlParams } from "../../components/utils";
import { getCookie, setCookies } from "../../components/utils/cookies";
import { GetApplicationInfoHandler } from "../../services/api/GetApplicationInfo/GetApplicationInfoHandler";
import { navigate } from "gatsby";
import Collapsible from "react-collapsible";
import { amplitudeTrackEvent, amplitudeTrackUser } from "../../components/utils/Amplitude";
import Alert from "../../components/atoms/Alert";
import "./review.scss";

const Review = (props) => {
  const { orderId, pdfId } = getUrlParams();
  const { language } = useI18next();

  useEffect(() => {
    if (orderId && pdfId) {
      setCookies('address_token', {
        order: orderId,
        applicationId: orderId,
        pdfId: pdfId,
      });
    }

    const orderCookie = getCookie('address_token');

    setState((prevState) => ({
      ...prevState,
      applicationId: orderCookie.applicationId,
      pdfId: orderCookie.pdfId,
    }));

    setScrollPage();

    if (orderCookie.applicationId) {
      fetchData(orderCookie.applicationId, props.pageContext.slug);
    } else {
      navigate(`/${props.pageContext.slug}`);
    }

    setCookies('app_id', { app_id: orderCookie.applicationId });
    setCookies('pdfId', { pdfId: orderCookie.pdfId });

    amplitudeTrackEvent('view_page_reviewPage', {
      form: props.pageContext.name.toLowerCase(),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [state, setState] = useState({
    form: props.pageContext.json.fields,
    structure: props.pageContext.json.structure,
    data: {},
    applicationId: '',
    scrollTo: false,
    loading: false,
    pdfId: '',
    addons: [],
    price: 0,
    pdf: {},
  });

  const setScrollPage = () => {
    if (state.scrollTo === false) {
      if (windowIsExist && window.location.hash !== '') {
        const hash = window.location.hash;
        document.querySelector(hash).scrollIntoView({ block: 'start', behavior: 'smooth' });
      } else {
        scrollBehaviorTo(0);
      }

      setState((prevState) => ({
        ...prevState,
        scrollTo: true,
      }));
    }
  };

  const fetchData = async (applicationId) => {
    const orderHandler = new GetOrderHandler({});
    const applicationNameHandler = new GetApplicationNameHandler({});
    const GetFormDetails = new GetFormDetailsHandler({});
    amplitudeTrackUser(applicationId);

    const data = {
      applicationId: applicationId,
    };

    setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    let dataNameHandler = {};
    dataNameHandler.reference = applicationId;
    const getApplicationInfo = new GetApplicationInfoHandler({});

    try {
      const response = await getApplicationInfo.customAction(data);

      if (response.status === 'registered') {
        const res = await Promise.all([
          orderHandler.customAction(data),
          applicationNameHandler.customAction(dataNameHandler),
          GetFormDetails.customAction({ formId: state.form.pdfId.value, addons: true }),
        ]);

        let form = {
          applicationName: res[1].application.formName,
          applicationNumber: res[1].application.formDescription,
        };

        setCookies('address_token', {
          reference: res[1].application.reference,
        });

        let formData = res[0].form;

        if (formData.addons && Array.isArray(formData.addons)) {
          const addons = buildAddons(formData.addons);
          formData.addons = addons.map((addon) => ({ ...addon, value: '1' }));
        }

        setState((prevState) => ({
          ...prevState,
          application: form,
          data: formData,
          loading: false,
          reference: res[1].application.reference,
          addons: buildAddons(res[2].addons),
          price: res[2].pdf.prices[0].amount,
          pdf: res[2].pdf,
        }));
      } else {
        navigate('/home');
      }
    } catch (error) {
      console.error(error);
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  let stepNames = [];
  state.structure.steps.map((step) => {
    let stepName = step.label.split('.')[1];
    stepNames.push(stepName);
    return stepNames;
  });

  const addons = Array.isArray(state.data.addons) ? state.data.addons : [];

  const fieldsToHideInReview = [
    'emailAddressValidation',
    'removeAdditionalPhoneNumber1',
    'removeAdditionalPhoneNumber2',
    'hasChangedName',
    'firstNameIncorrectCheck',
    'middleNameIncorrectCheck',
    'lastNameIncorrectCheck',
    'dateOfBirthIncorrectCheck',
    'placeOfBirthIncorrectCheck',
    'genderIncorrectCheck',
  ];

    const sectionOpened = (step) => {
      if (windowIsExist) {
        return window.location.hash !== ""
          ? window.location.hash.replace("#", "") === step.name
          : state.structure.steps.indexOf(step) === 0;
      }
    };

    const submitApplicationContinue = () => {
      const orderCookie = getCookie("address_token");
      const tagCookie = getCookie("tag_param");

      if (orderCookie.urlRedirect) {
        const redirect = `${orderCookie.urlRedirect}/payment?order=${orderCookie.applicationId}&pdf=${orderCookie.pdfId}&origin=${orderCookie.urlOrigin}&redirect=${orderCookie.urlRedirect}`;
        isObjectEmpty(tagCookie) ? window.location.href = redirect : window.location.href = `${redirect}&tag=${tagCookie.tag}`;
      } else {
        navigate(`/payment`);
      }
    };

  return (
    <Layout
      headerTitle={props.pageContext.title[language] ? props.pageContext.title[language] : undefined}
      hideMenuButton={false}
      headerBigMaxWidth
      footerBigMaxWidth
      disclaimerBigMaxWidth>
      {state.loading && <Loading full />}
      <Nav
        currentStep={state.structure.steps.length}
        stepNames={stepNames}
        customStepNames={["Review Application"]}
      />
      <Wrapper twoCols>
        <div className="review">
          <div className={`review__wrapper u-box`}>
            <h1 className={`review__title extra-large`}>Personal Application Review</h1>
            <p className={`review__subtitle big`}>
              Review the data below and make changes if needed
            </p>
            {state.structure.steps.map((step, key) => {
              let groupTitle = step.label;
              let fields = {};
              if (key === 2 || key === 3)
                groupTitle = `${groupTitle.split("<div>")[0]} (Optional)`;
              let stepIsEmpty = true;
              return (
                <Collapsible
                  key={"group" + key + step.name}
                  trigger={
                    <>
                      <h2 className="review__item-title big">{groupTitle.split("<div>")[0]}</h2>
                      <div className="review__item-right">
                        <div className="review__item-status">
                          <span>Completed</span>
                          <ReactSVG
                            src="/svg/check-with-circle.svg"
                            className="review__item-statusIcon"
                            title="review-status"
                          />
                        </div>
                        <ReactSVG
                          src="/svg/expand.svg"
                          className="review__item-expandIcon"
                          title="expand"
                        />
                      </div>
                    </>
                  }
                  className="review__item"
                  open={sectionOpened(step)}
                  openedClassName="review__item"
                  triggerClassName=""
                  triggerOpenedClassName=""
                  contentInnerClassName=""
                  containerElementProps={{ id: step.name }}
                  transitionTime={200}
                  triggerTagName="div">
                  {step.groups.map((group, index) => {
                    return !group.hiddenInReview ? (
                      <div
                        key={`step-${index}-${group.name}`}
                        id={`${group.name}${index}`}>
                        {group.fields.map((field, key) => {
                          if (state.data[field]) {
                            stepIsEmpty = false;
                          }
                          let question = state.form[field].reviewLabel
                            ? state.form[field].reviewLabel
                            : state.form[field].label;
                          let relative =
                            (field === "relative1Unknown" && "Relative 1") ||
                            (field === "relative2Unknown" && "Relative 2");
                          let response =
                            filterDataReview(state.form[field], state.data[field]) ===
                            undefined
                              ? ""
                              : filterDataReview(state.form[field], state.data[field]);

                          if (
                            response === "0" &&
                            (field === "relative2Unknown" || field === "relative1Unknown")
                          )
                            response = " ";
                          if (
                            response === "1" &&
                            (field === "relative2Unknown" ||
                              field === "relative1Unknown" ||
                              field === "changingGender")
                          )
                            response = "Yes";
                          if (field in fields) {
                            return false;
                          }
                          if (
                            fieldsToHideInReview.includes(field) ||
                            (response === "0" && field === "changingGender")
                          ) {
                            return false;
                          }

                          if (field === "addons") {
                            if (typeof state.data[field] !== "undefined") {
                              response = state.data[field]
                                .map((item) => item.title)
                                .join(", ");
                            }
                          }
                          fields[field] = field;
                          return (
                            <div
                              className={`review__field`}
                              key={`${field.name}${key}`}>
                              {response.length !== 0 && (
                                <p className={`review__field__question medium`}>
                                  {question === "Unknown" ? `${relative}` : `${question}`}
                                </p>
                              )}
                              {response.length !== 0 && (
                                <p className={`review__field__answer medium`}>
                                  {question === "Unknown" && response === "Yes"
                                    ? `Unknown`
                                    : `${response}`}
                                </p>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    ) : null;
                  })}

                  {stepIsEmpty ? (
                    <p className="review__emptyCopy">
                      For the moment you have not added any {step.name.replace("-", " ")}
                    </p>
                  ) : null}

                    <div className={`edit__wrapper`}>
                      <Button
                        tertiary
                        dataQa={`edit-${step.name}`}
                        label={`Edit section`}
                        link={`/edit/${props.pageContext.slug}/#${step.name}`}
                      />
                    </div>
                  </Collapsible>
                );
              })}
              <Wrapper
                flex
                classNames={`review__footer`}>
                <div className={`disclaimer__wrapper`}>
                  <Alert
                    className="disclaimer__wrapper__alert"
                    content="<b>Important:</b> Make sure all the data entered is correct before continue."
                    showLeftIcon
                  />
                </div>
                <div>
                  <Button
                    primary
                    buttonId="submit-application"
                    dataQa={`submit-application`}
                    label={`Continue`}
                    onClick={() => {
                      submitApplicationContinue();
                    }}
                  />
                </div>
              </Wrapper>
            </div>
          </div>
          <div className="review__summary">
            <OrderSummary
              addons={addons}
              price={state.price}
              pdf={state.pdf}
              fixed
              form
              isOpen={true}
            />
            <ContactSummary form />
          </div>
        </Wrapper>
      </Layout>
    );
  };


export default Review;
