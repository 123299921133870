import React, { useContext, useEffect, useState } from "react";
import SiteDataContext from "../../../context/SiteDataContext";
import { getCookie } from "../../utils/cookies";
import { useStaticQuery, graphql } from "gatsby";
import classNames from "classnames";
import { ReactSVG } from "react-svg";
import(`./styles.scss`);

export const ContactSummary = (props) => {
  const [secondParaph, setSecondParaph] = useState(false);
  const [copy, setCopy] = useState("");
  const siteInfo = getCookie("site_info");

  const classnames = classNames(
    `contactSummary__wrapper contactSummary__light u-box u-box--small ${props.classNames}`,
    {
      "contactSummary--form": props.form,
      "contactSummary--payment": props.payment,
    }
  );

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          web_design
        }
      }
    }
  `);

  const siteTheme = data.site.siteMetadata.web_design;
  const siteData = useContext(SiteDataContext).siteData || null;
  const contactHours = siteData?.contact_hours;

  useEffect(() => {
    const siteHasPhoneOrChat = siteInfo.hasPhone === true || siteInfo.hasChat === true;
    setSecondParaph(siteHasPhoneOrChat);
    let copyType;

    if (siteInfo.hasPhone === true && siteInfo.hasChat === false) copyType = "1";
    if (siteInfo.hasPhone === false && siteInfo.hasChat === true) copyType = "2";

    switch (copyType) {
      case "1":
        setCopy(`Get help <b>online via phone</b> (${contactHours})`);
        break;
      case "2":
        setCopy(`Get help <b>online vía chat</b> (${contactHours})`);
        break;
      default:
        setCopy(`Get help <b>online via phone or chat</b> (${contactHours})`);
    }
  }, [siteInfo, contactHours]);

  return (
    <div className={classnames}>
      <div className="contactSummary__body">
        <div className="contactSummary__item">
          <ReactSVG
            className="contactSummary__icon"
            src={`/svg/themes/${siteTheme}/hours.svg`}
          />
          <p className="contactSummary__copy">
            Our support team is here to help you <b>24/7 via email or form</b>
          </p>
        </div>
        {secondParaph && (
          <div className="contactSummary__item">
            <ReactSVG
              className="contactSummary__icon"
              src={`/svg/themes/${siteTheme}/stepbystep.svg`}
            />
            <p
              className="contactSummary__copy"
              dangerouslySetInnerHTML={{ __html: copy }}
            />
          </div>
        )}
      </div>
    </div>
  );
};
